import React from 'react'
import { CollectionToolTipContainer, TextContent } from 'styles/sharedStyles'

const WalletToolTip = () => {
  return (
    <CollectionToolTipContainer>
      A crypto wallet is an application or hardware device that allows individuals to store and
      retrieve digital items.
    </CollectionToolTipContainer>
  )
}

export default WalletToolTip
