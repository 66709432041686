import { useWeb3React } from '@web3-react/core'
import { useAppSelector } from 'hooks/useAppSelector'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { OpacityAnimation } from 'styles/sharedStyles'
import LoginScreen from '../../modules/login/LoginScreen'
import HeaderMetaInfo from '../../shared/headerMetaInfo/HeaderMetaInfo'

const Login: NextPage = () => {
  const { isLoggedIn } = useAppSelector(state => state.user)
  const router = useRouter()
  const { account } = useWeb3React()
  if (account && isLoggedIn) {
    //@ts-ignore
    router.push(router.query.redirect || '/profile')
  }
  return (
    <>
      <HeaderMetaInfo pageTitle="Login-Jungle" />
      <OpacityAnimation>
        <LoginScreen />
      </OpacityAnimation>
    </>
  )
}

export default Login
